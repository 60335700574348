export const weapons = {
  sword: {
    id: 'sword',
    name: 'Sword',
  },
  bow: {
    id: 'bow',
    name: 'Bow',
  },
  polearm: {
    id: 'polearm',
    name: 'Polearm',
  },
  claymore: {
    id: 'claymore',
    name: 'Claymore',
  },
  catalyst: {
    id: 'catalyst',
    name: 'Catalyst',
  },
};
